import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.css';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../data/translation';

function Navbar({ onNavigate }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useLanguage();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    onNavigate(path);
    setIsOpen(false);
    window.scrollTo(0, 0);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active-link' : '';
  };

  const getLogoText = () => {
    const path = location.pathname;
    if (path === '/home' || path === '/') {
      return 'Logan@portfolio';
    }
    return `Logan@portfolio${path}.js`;
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href="/home" 
           className="navbar-logo"
           onClick={(e) => handleClick(e, '/home')}>
          <img src="/img/logo.png" alt="Logo" className="nav-logo-img" />
          <span className="logo-text">{getLogoText()}</span>
        </a>
        
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <a href="/home" 
               className={`nav-link ${isActive('/home')}`}
               onClick={(e) => handleClick(e, '/home')}>
              {getTranslation(language, 'navbar.home')}.js
            </a>
          </li>
          <li className="nav-item">
            <a href="/about" 
               className={`nav-link ${isActive('/about')}`}
               onClick={(e) => handleClick(e, '/about')}>
              {getTranslation(language, 'navbar.about')}.js
            </a>
          </li>
          <li className="nav-item">
            <a href="/projects" 
               className={`nav-link ${isActive('/projects')}`}
               onClick={(e) => handleClick(e, '/projects')}>
              {getTranslation(language, 'navbar.projects')} .js
            </a>
          </li>
          <li className="nav-item">
            <a href="/contact" 
               className={`nav-link ${isActive('/contact')}`}
               onClick={(e) => handleClick(e, '/contact')}>
              {getTranslation(language, 'navbar.contact')}.js
            </a>
          </li>
          <li className="nav-item">
            <a href="https://blog.logandelmairedev.com" 
               className="nav-link blog-link"
               target="_blank"
               rel="noopener noreferrer">
              Blog
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar; 